import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Heading from 'components/Heading';
import Tab from 'components/Tab';
import TabContent from 'components/Tab/TabContent';

const Wrapper = styled.div``;

function useIdentityVerificationData() {
  const digitalOnboardingData = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          fileAbsolutePath: {
            glob: "**/contents/products/trust-identity/identity-verifycation/*.md"
          }
        }
      ) {
        nodes {
          id
          frontmatter {
            title
            image {
              publicURL
            }
          }
          html
        }
      }
    }
  `);
  return useMemo(() => {
    return digitalOnboardingData.allMarkdownRemark.nodes.map((item, i) => ({
      title: item.frontmatter.title,
      id: item.id,
      content: (
        <TabContent
          imageLeft={i % 2 === 0}
          title={item.frontmatter.title}
          html={item.html}
          image={item.frontmatter.image}
        />
      ),
    }));
  }, [digitalOnboardingData]);
}

function IdentityVerification() {
  const digitalOnboardingTabItems = useIdentityVerificationData();

  return (
    <Wrapper>
      <Heading centered title="Identity Verification" />
      <Tab items={digitalOnboardingTabItems} />
    </Wrapper>
  );
}

export default IdentityVerification;
