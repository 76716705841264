import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Heading from 'components/Heading';
import Spacer from 'components/Spacer';
import PolygonIcon from 'components/ContentIcon';
import { Row, Col, Visible } from 'react-grid-system';
import Card from 'components/Card';
import breakpoints from 'utils/breakpoints';

const Wrapper = styled.div``;
const CardWrapper = styled.div`
  @media screen and (min-width: ${breakpoints.md}) {
    height: 100%;
  }
`;

const StyledRow = styled(Row)`
  margin-top: 40px;
  @media screen and (min-width: ${breakpoints.md}) {
    margin-top: 80px;
  }
`;

const StyledCard = styled(Card)`
  text-align: center;
  padding-left: 43px;
  padding-right: 43px;
`;

const CardText = styled.p`
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #1c2f45;
  margin: 0;
`;

const HeadingText = styled.div`
  font-weight: normal;

  strong {
    margin: 0 9px;
  }
`;

function WhyChooseUs({ className }) {
  return (
    <Wrapper className={className}>
      <Heading
        centered
        name="Why choose us?"
        title={
          <HeadingText>
            <strong>40% faster</strong>
            &amp;
            <strong>10x more accurate</strong>
            than an
            <br />
            average human verification
          </HeadingText>
        }
      />
      <StyledRow align="strech">
        <Col md={4} lg={3}>
          <CardWrapper>
            <StyledCard icon={<PolygonIcon type="Quickly" />}>
              <CardText>
                Quickly verify a loan applicant and speed-up the onboarding
                process
              </CardText>
            </StyledCard>
          </CardWrapper>
          <Visible xs sm>
            <Spacer space="10px" />
          </Visible>
        </Col>
        <Col md={4} lg={3}>
          <CardWrapper>
            <StyledCard icon={<PolygonIcon type="Robot" />}>
              <CardText>
                Optimize lending operations by reducing human errors and
                operating cost
              </CardText>
            </StyledCard>
          </CardWrapper>
          <Visible xs sm>
            <Spacer space="10px" />
          </Visible>
        </Col>
        <Col md={4} lg={3}>
          <CardWrapper>
            <StyledCard icon={<PolygonIcon type="Fast" />}>
              <CardText>
                A fast and scalable solution with easy integration, no privacy /
                security concerns
              </CardText>
            </StyledCard>
          </CardWrapper>
          <Visible xs sm>
            <Spacer space="10px" />
          </Visible>
        </Col>
        <Col md={4} lg={3}>
          <CardWrapper>
            <StyledCard icon={<PolygonIcon type="DisbursedLoans" />}>
              <CardText>Enable a paperless consumer lending process</CardText>
            </StyledCard>
          </CardWrapper>
          <Visible xs sm>
            <Spacer space="10px" />
          </Visible>
        </Col>
      </StyledRow>
    </Wrapper>
  );
}

WhyChooseUs.defaultProps = {
  className: null,
};

WhyChooseUs.propTypes = {
  className: PropTypes.string,
};

export default WhyChooseUs;
